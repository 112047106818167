<template>
	<div class="c">
		<div class="c-top">
			<el-button size="mini" type="primary" @click="changeEdit" v-if="!isEdit">编辑</el-button>
		</div>
		<el-form ref="form" :model="obj" label-width="140px">
			<el-form-item label="是否开启自动报价:">
				<el-switch
				    v-model="AUTO_QUOTE"
				    class="ml-2"
				    active-color="#13ce66"
					 :disabled="!isEdit"
				/>
			</el-form-item>
			<div class="c-title" style="padding: 20px 0;">利润点数
			</div>
			<div style="margin:0 0 10px 2%">
				<el-input-number v-model="num" :precision="2" :step="0.1" :max="100" :disabled="!isEdit" @change="inputNumber"/>（单位：%）
			</div>
			<el-row v-for="(item, index) in tempList" :key="index">
				<el-col :span="7" v-if="item.setTpCd === 'PROFIT_POINTS'">
					<el-form-item label="转询的利润点数为:">
						<el-input placeholder="请输入" v-model="item.value" clearable :disabled="!isEdit" />
					</el-form-item>
				</el-col>
				<el-col :span="21" v-else-if="item.setTpCd === 'COLLECTION_QR_CODE'">
					<el-form-item label="收款二维码:">
						<upload-file v-model="item.files" isType="add" list-type="picture-card" :limit="1"
							:disabled="!isEdit" :list="item.list || []" />
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		
		
		
		<!-- 微信的绑定和解绑   -->
		<WeChatBind :isBind="userInfo.wechatFlag === '1'" @refresh="handlerRefresh"></WeChatBind>

		<div class="c-footer" v-if="isEdit">
			<el-button type="primary" size="mini" style="margin-right: 20px;" @click="confirm">确认</el-button>
			<el-button size="mini" style="margin-left: 20px;" @click="cancel">取消</el-button>
		</div>
	</div>
</template>

<script>
	import {
		runNumber
	} from "@/common/js/common.js";
	import uploadFile from "@/components/publicComponent/uploadFile.vue";
	import {
		mapActions
	} from "vuex";
	import {
		ElMessage
	} from "element-plus";
	import WeChatBind from "../components/weChatBind";

	export default {
		data() {
			return {
				isEdit: false,
				tempList: [{
						setTpCd: "PROFIT_POINTS", //利润点数
						value: "",
						files: [],
						list: []
					},
					{
						setTpCd: "COLLECTION_QR_CODE", //收款码
						value: "",
						files: [],
						list: []
					}
				],
				num:0,
				/** 存储用户信息 */
				userInfo: {},
				AUTO_QUOTE:false,
			};
		},
		components: {
			uploadFile,
			WeChatBind
		},
		methods: {
			...mapActions("systemModule", [
				"userSetUserSetBatch", //批量修改
				"userListUserSetByUserId", //获取批量修改列表
				"getUserInfo" //请求用户信息的接口
			]),

			/** 请求用户信息的方法 */
			async handleUserInfo() {
				try {
					const res = await this.getUserInfo();
					const {
						data,
						code
					} = res.data;
					if (code === "0") {
						this.userInfo = data;
					}
				} catch (e) {
					console.log(e);
				}
			},

			changeEdit() {
				//编辑的点击事件
				this.isEdit = true;
			},
			cancel() {
				this.isEdit = false;
			},
			inputNumber(currentValue){
				// console.log(currentValue);
				this.tempList[0].value=String(currentValue)
			},
			getInfo() {
				this.userListUserSetByUserId().then(res => {
					let {
						code,
						data
					} = res.data;
					if (code === "0" && data.length) {
						data.map(item => {
							item.list = JSON.parse(item.files || "[]");
							item.files = [];
							if(item.setTpCd === 'AUTO_QUOTE'){
								this.AUTO_QUOTE = item.value === '1';
							}
						});
						this.tempList = data;
						this.num=Number(this.tempList[0].value)
						console.log(this.tempList);
					}
				});
			},
			confirm() {
				var data = JSON.parse(JSON.stringify(this.tempList));
				data.map(item => {
					item.files = item.files.length ? item.files[0] : "";
					delete item.list;
				});
				// data.push({
				// 	files: "",
				// 	setTpCd:"AUTO_QUOTE",
				// 	value: this.AUTO_QUOTE ? '1' : '0',
				// })
				this.userSetUserSetBatch(data).then(res => {
					let {
						code,
						data
					} = res.data;
					if (code === "0") {
						ElMessage({
							type: "success",
							message: "修改成功"
						});
						//更新页面数据
						this.getInfo();
					}
				});
			},
			handlerRefresh() {
				this.handleUserInfo();
			},
		},
		created() {
			this.getInfo();
			this.handleUserInfo();
		}
	};
</script>

<style scoped lang="less">
	@import "../style/index.less";
</style>
